
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeMount,
  onActivated,
  watch,
} from "vue";
import store, { MutationNames } from "@/store/index";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { getBooks, getTeacherBooks, getTeacherInfo } from "@/services";
import { loadMicroApp } from "qiankun";
import servers from "@/config/servers";
import config from "@/config/app.config";
import {
  eventCenter,
  event,
  usePageTableContext,
} from "@evideo/frontend-utils";
import pageTableContextMap from "@/utils/page-table-context-map";
import JsBridge from "../../jsBridge";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import _ from "lodash";
import logger from "@evideo/logger";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    let loading = ref(true);
    let preloadPptFun = "";
    const router = useRouter();
    const route = useRoute();
    const teacherName = ref(store.state.nickName);
    let bookType = ref("");
    let message = ref("");
    let total = ref(1);
    let current_page = ref(1);
    let teachMaterialeight = reactive([]);
    let teachMaterialList = reactive([]);
    let switchTeachMaterialList = reactive([]);
    let middleTeachMaterialeight = reactive([]);
    let lastTeachMaterialeight = reactive([]);
    let slideTotalList = reactive([]);
    let reloadData = false;
    let mySwiper = ref(null);
    let swiperPage = ref(1);
    let num = ref(10);
    let firstLoading = ref(true);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    const device = navigator.userAgent;
    const android =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    // official:教材中心 ｜｜myCourse:我的教材
    bookType.value = route.query.teachMaterialType;
    logger.info("bookType", route.query);
    // const androidToken =
    //   // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNDIwNTc1MywiZXhwIjoxNjY5NzM3NzUzLCJuYmYiOjE2MzQyMDU3NTMsImp0aSI6IkNSQ0lIaFBYb2lCUUExTFkiLCJzdWIiOjE3NywicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.iRhmc0KRXTThBiY5X4WfulAgTOt5pLQpKhCEhUX_ZZg";
    //   // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNDAyMTM4NCwiZXhwIjoxNjM0NjI2MTg0LCJuYmYiOjE2MzQwMjEzODQsImp0aSI6IlZHdHZFRU1nNlpZS1N2MHYiLCJzdWIiOjEyMywicHJ2IjoiYjI3YmVlMjNiYWY1NDI5ZjViOWEyMTY5NmZkZTAzYzI3NzA0NGVhNSJ9.wqY29o1JSdRT5I1ZIUjhB_znJvwgvoAwrjuaELi8l6o";
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTYzNTQwMTAyMSwiZXhwIjoxNjcwOTMzMDIxLCJuYmYiOjE2MzU0MDEwMjEsImp0aSI6InFqeDh0b0lZQW5BNGZJSjkiLCJzdWIiOjEyMywicHJ2IjoiYjI3YmVlMjNiYWY1NDI5ZjViOWEyMTY5NmZkZTAzYzI3NzA0NGVhNSJ9.9FpFxm0OBJfvhVIumfc8QQhdO_RfYtfEmiLCHq9tqEQ";
    // store.commit(MutationNames.CHANGE_TOKEN, androidToken);

    const contextPage = ref(1);
    const { getContextInfo, saveContextInfo, deleteContextInfo } =
      usePageTableContext();

    // 从教材详情返回时读取上次离开时的页码等参数
    const handleGetContextInfo = () => {
      const context = getContextInfo(pageTableContextMap.MATERIAL_LIST);
      logger.info("get context ===> ", context);
      const { currentPage } = context;
      contextPage.value = currentPage;
      deleteContextInfo(pageTableContextMap.MATERIAL_LIST);
    };

    // 保存当前页码
    const handleSaveContextInfo = () => {
      const pageContext = {
        currentPage: current_page.value,
      };
      logger.info("save context ===> ", pageContext);
      saveContextInfo(pageTableContextMap.MATERIAL_LIST, pageContext);
    };
    const onSwiper = (swiper) => {
      logger.info("swiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", current_page.value);
    };
    onBeforeMount(() => {
      teacherName.value = store.state.nickName;
      logger.info("reloadData", reloadData);
      // if (reloadData) {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.once("teachMaterialGoBack", (event, message) => {
          // 接收electron原生返回的信息
          logger.info("返回home", event, message);
          goToHome();
        });
      }
      handleGetContextInfo();
      getBookInfo(contextPage.value);
    });
    onMounted(() => {
      logger.info("data", teachMaterialeight);
      logger.info("totalData====>", teachMaterialList);
      init();
      // teachMaterialeight.push(...teachMaterialList.slice(0, 8));
      firstLoading.value = false;
    });
    onActivated(() => {
      logger.info("teacherName", teacherName.value);
      bookType.value = route.query.teachMaterialType;
      teacherName.value = store.state.nickName;
      if (android) {
        // 通知安卓将页面show出来
        logger.info("通知android显示", new Date(), "yyyy-MM-dd hh:mm:ss");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
      if (reloadData) {
        if (window.require) {
          const { ipcRenderer } = window.require("electron");
          ipcRenderer.once("teachMaterialGoBack", (event, message) => {
            // 接收electron原生返回的信息
            logger.info("返回home", event, message);
            goToHome();
          });
        }
        getBookInfo();
      }
    });
    const btnBack = () => {
      logger.info("我要调安卓方法了");
      reloadData = true;
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      current_page.value = 1;
      total.value = 1;
      // window.WebViewJavascriptBridge.callHandler(
      //   "closeTeachMaterial", // native中注册时的key值
      //   null // native中注册方法中的传入的数据
      // );
      router.replace({
        path: "/blank",
        query: {
          skinType: 0,
        },
      });
      // alert("触发了安卓返回的方法");
    };
    const loadPPT = async () => {
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      // let entryUrl = "http://192.168.74.175:8090/";
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: "teachPptPC",
        el: "#ppt-preload",
        entry: entryUrl,
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          preload: true,
        },
        configuration: { $$cacheLifecycleByAppName: true },
      });
    };
    const getBookInfo = (contextPage) => {
      logger.info("看看教材页面有没有保存当前页===>", contextPage);
      if (contextPage === undefined) {
        contextPage = 1;
      }
      loading.value = true;
      logger.info("重新获取数据", bookType.value);
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      // current_page.value = 1;
      // total.value = 1;
      if (bookType.value === "official") {
        logger.info("official");
        getBooks({ per_page: 10, show_modules: 1, page: contextPage }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if (contextPage % 3 == 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 1;
            }
            if (contextPage % 3 == 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 2;
            }
            if (contextPage % 3 == 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 3;
            }
            // 判断结束
            if (contextPage === 1 && total.value !== 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = true;
            } else if (contextPage === total.value && total.value === 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = false;
            } else if (contextPage === total.value && total.value !== 1) {
              leftBorderFlag.value = true;
              rightBorderFlag.value = false;
            } else {
              leftBorderFlag.value = true;
              rightBorderFlag.value = true;
            }
            current_page.value = contextPage;
          }
        );
        getBooks({ per_page: 10, show_modules: 1, page: contextPage + 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage + 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
        getBooks({ per_page: 10, show_modules: 1, page: contextPage - 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage - 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
      }
      if (bookType.value === "characteristicClassroom") {
        // 特色课堂
        logger.info("characteristicClassroom");
        getBooks({ per_page: 10, show_modules: 2, page: contextPage }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if (contextPage % 3 == 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 1;
            }
            if (contextPage % 3 == 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 2;
            }
            if (contextPage % 3 == 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
              total.value = res.data.meta.last_page;
              mySwiper.value.activeIndex = 3;
            }
            // 判断结束
            if (contextPage === 1 && total.value !== 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = true;
            } else if (contextPage === total.value && total.value === 1) {
              leftBorderFlag.value = false;
              rightBorderFlag.value = false;
            } else if (contextPage === total.value && total.value !== 1) {
              leftBorderFlag.value = true;
              rightBorderFlag.value = false;
            } else {
              leftBorderFlag.value = true;
              rightBorderFlag.value = true;
            }
            current_page.value = contextPage;
          }
        );
        getBooks({ per_page: 10, show_modules: 2, page: contextPage + 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage + 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage + 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );
        getBooks({ per_page: 10, show_modules: 2, page: contextPage - 1 }).then(
          (res) => {
            // 在这里判断数据放到哪一个窗口
            if ((contextPage - 1) % 3 === 1) {
              teachMaterialeight.length = 0;
              teachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 2) {
              middleTeachMaterialeight.length = 0;
              middleTeachMaterialeight.push(...res.data.items);
            }
            if ((contextPage - 1) % 3 === 0) {
              lastTeachMaterialeight.length = 0;
              lastTeachMaterialeight.push(...res.data.items);
            }
            // 判断结束
          }
        );

        // getBooks({ per_page: 10, show_modules: 2, page: 1 }).then((res) => {
        //   teachMaterialeight.push(...res.data.items);
        //   total.value = res.data.meta.last_page;
        // });
        // getBooks({ per_page: 10, show_modules: 2, page: 2 }).then((res) => {
        //   middleTeachMaterialeight.push(...res.data.items);
        //   total.value = res.data.meta.last_page;
        // });
        // getBooks({ per_page: 10, show_modules: 2, page: 3 }).then((res) => {
        //   lastTeachMaterialeight.push(...res.data.items);
        //   total.value = res.data.meta.last_page;
        // });
        // getBooks({
        //   per_page: 1000,
        //   // subject_cat_codes: [
        //   //   "subject_piano",
        //   //   "subject_hulusi",
        //   //   "subject_flute",
        //   //   "subject_composition",
        //   // ],
        //   show_modules: 2,
        // }).then((data) => {
        //   if (contextPage.value > 1) {
        //     current_page.value = contextPage.value;
        //     contextPage.value = 1;
        //   }
        //   const startSlicIndex = (current_page.value - 1) * 10;
        //   teachMaterialList = data.data.items;
        //   teachMaterialeight.push(
        //     ...teachMaterialList.slice(startSlicIndex + 0, startSlicIndex + 10)
        //   );
        //   total.value = Math.ceil(teachMaterialList.length / 10);
        // });
      }
      if (bookType.value === "myCourse") {
        // 我的课件
        logger.info("myCourse");
        getTeacherBooks({ per_page: 10, page: contextPage }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if (contextPage % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...res.data.items);
            total.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 1;
          }
          if (contextPage % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...res.data.items);
            total.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 2;
          }
          if (contextPage % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...res.data.items);
            total.value = res.data.meta.last_page;
            mySwiper.value.activeIndex = 3;
          }
          // 判断结束
          if (contextPage === 1 && total.value !== 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = true;
          } else if (contextPage === total.value && total.value === 1) {
            leftBorderFlag.value = false;
            rightBorderFlag.value = false;
          } else if (contextPage === total.value && total.value !== 1) {
            leftBorderFlag.value = true;
            rightBorderFlag.value = false;
          } else {
            leftBorderFlag.value = true;
            rightBorderFlag.value = true;
          }
          current_page.value = contextPage;
        });
        getTeacherBooks({ per_page: 10, page: contextPage + 1 }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((contextPage + 1) % 3 === 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...res.data.items);
          }
          if ((contextPage + 1) % 3 === 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...res.data.items);
          }
          if ((contextPage + 1) % 3 === 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...res.data.items);
          }
          // 判断结束
        });
        getTeacherBooks({ per_page: 10, page: contextPage - 1 }).then((res) => {
          // 在这里判断数据放到哪一个窗口
          if ((contextPage - 1) % 3 === 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...res.data.items);
          }
          if ((contextPage - 1) % 3 === 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...res.data.items);
          }
          if ((contextPage - 1) % 3 === 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...res.data.items);
          }
          // 判断结束
        });

        // getTeacherBooks({ per_page: 1000 }).then((data) => {
        //   if (contextPage.value > 1) {
        //     current_page.value = contextPage.value;
        //     contextPage.value = 1;
        //   }
        //   const startSlicIndex = (current_page.value - 1) * 10;
        //   teachMaterialList = data.data.items;
        //   teachMaterialeight.push(
        //     ...teachMaterialList.slice(startSlicIndex + 0, startSlicIndex + 10)
        //   );
        //   total.value = Math.ceil(teachMaterialList.length / 10);
        // });
        // getTeacherBooks({ per_page: 10, page: 1 }).then((res) => {
        //   teachMaterialeight.push(...res.data.items);
        //   total.value = res.data.meta.last_page;
        // });
        // getTeacherBooks({ per_page: 10, page: 2 }).then((res) => {
        //   middleTeachMaterialeight.push(...res.data.items);
        //   total.value = res.data.meta.last_page;
        // });
        // getTeacherBooks({ per_page: 10, page: 3 }).then((res) => {
        //   lastTeachMaterialeight.push(...res.data.items);
        //   total.value = res.data.meta.last_page;
        // });
      }
      loading.value = false;
      setTimeout(() => {
        loading.value && (loading.value = false);
      }, 60000);
    };
    const goToMaterialPpt = (id, url, name) => {
      reloadData = false;
      handleSaveContextInfo();
      router.push({
        path: "/materialPpt",
        query: {
          bookId: id,
          url,
          name: encodeURI(name),
          firstLoadType: false,
          teachMaterialType: bookType.value,
          skinType: "cartoon",
        },
      });
    };
    const goToHome = () => {
      reloadData = true;
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      current_page.value = 1;
      total.value = 1;
      router.push({
        path: "/home",
      });
      // history.back();
    };
    const goToLogin = () => {
      reloadData = true;
      teachMaterialList.length = 0;
      teachMaterialeight.length = 0;
      current_page.value = 1;
      total.value = 1;
      router.push({
        // path: "/home",
        path: "/login",
      });
      // history.back();
    };
    const goWhiteboard = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    const goControl = () => {
      ElMessage({
        offset: 200,
        message: "开发中,敬请期待......",
        center: true,
      });
    };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      logger.info(
        "点击按钮切换到下一页函数里拿到的当前页数是===>",
        current_page.value + 1
      );
      if (current_page.value === total.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slideNext();
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      logger.info(
        "点击按钮切换到上一页函数里拿到的当前页数是===>",
        current_page.value - 1
      );
      if (current_page.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        mySwiper.value.slidePrev();
      }
    }, 200);
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = current_page.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info("当前窗口是在==>", mySwiper.value.activeIndex);
        logger.info(
          "页面切换到上一页函数里拿到的当前页数是===>",
          current_page.value - 1
        );
        current_page.value--;
        getPrevData(current_page.value - 1);
      }
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      if (!firstLoading.value) {
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = current_page.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info(
            "页面切换到下一页函数里拿到的当前页数是===>",
            current_page.value + 1
          );
          // teachMaterialeight.length = 0;
          // teachMaterialeight.push(...slideTotalList[current_page.value + 1]);
          logger.info(
            "现在的slide里的数据是:===>",
            teachMaterialeight,
            middleTeachMaterialeight,
            lastTeachMaterialeight
          );
          current_page.value++;
          getNextData(current_page.value + 1);
        }
      }
    };
    //  切换时获取下一页数据的函数
    const getNextData = (page) => {
      if (bookType.value === "official") {
        logger.info("official");
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 1,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value + 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
        });
      }
      if (bookType.value === "characteristicClassroom") {
        logger.info("characteristicClassroom");
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 2,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value + 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
        });
      }
      if (bookType.value === "myCourse") {
        // 我的课件
        logger.info("myCourse");

        getTeacherBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value + 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value + 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
        });
      }
    };
    //  切换时获取上一页数据的函数
    const getPrevData = (page) => {
      if (bookType.value === "official") {
        logger.info("official");
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 1,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info(
            "即将请求上一页数据，拿数据时的页面是===>",
            current_page.value
          );
          if ((current_page.value - 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
        });
      }
      if (bookType.value === "characteristicClassroom") {
        logger.info("characteristicClassroom");
        // 教材
        getBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
          show_modules: 2,
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value - 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
        });
      }
      if (bookType.value === "myCourse") {
        // 我的课件
        logger.info("myCourse");
        getTeacherBooks({
          per_page: 10,
          page: page,
          // subject_cat_codes: "subject_k12_music",
        }).then((data) => {
          switchTeachMaterialList = data.data.items;
          logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
          logger.info("拿数据时的页面是===>", current_page.value);
          if ((current_page.value - 1) % 3 == 1) {
            teachMaterialeight.length = 0;
            teachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 2) {
            middleTeachMaterialeight.length = 0;
            middleTeachMaterialeight.push(...switchTeachMaterialList);
          }
          if ((current_page.value - 1) % 3 == 0) {
            lastTeachMaterialeight.length = 0;
            lastTeachMaterialeight.push(...switchTeachMaterialList);
          }
        });
      }
    };
    //  初始化滑屏
    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };
    watch(
      () => current_page.value,
      () => {
        if (current_page.value === 1) {
          leftBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          leftBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => current_page.value,
      () => {
        logger.info("总页数", total.value);
        if (current_page.value === total.value) {
          rightBorderFlag.value = false;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else {
          rightBorderFlag.value = true;
          logger.info("这里开启循环", leftBorderFlag.value);
        }
      }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        logger.info("在这看触摸距离startX===>", mySwiper.value.touches.startX);
        logger.info(
          "在这看触摸距离currentX===>",
          mySwiper.value.touches.currentX
        );
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          current_page.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          current_page.value === total.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        }
      }, 200),
      { deep: true }
    );
    return {
      loading,
      total,
      current_page,
      teacherName,
      teachMaterialeight,
      middleTeachMaterialeight,
      lastTeachMaterialeight,
      bookType,
      mySwiper,
      swiperPage,
      slideTotalList,
      num,
      firstLoading,
      goToHome,
      goToLogin,
      goToMaterialPpt,
      goWhiteboard,
      goControl,
      switchTeachMaterialList,
      leftBorderFlag,
      rightBorderFlag,
      previous,
      next,
      btnBack,
      onSwiper,
      onSlideChange,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      init,
      getNextData,
      getPrevData,
    };
  },
});
